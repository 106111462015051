<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
              height="46"
              v-if="itemId"
          >
            <v-icon color="secondary" size="28">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 px-1">
      <div class="document-header">
        <div class="conducted-box d-flex">
          <v-alert
              dense
              text
              class="mb-0 py-1"
              :color="conducted ? 'success' : 'secondary'"
              :type="conducted ? 'success' : 'warning'"
              style="flex: 1"
          >
            {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
          </v-alert>
        </div>
        <div class="document-header-row px-2 pt-2 pb-1">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required/>
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <select_input
                v-model="subdivision_id"
                :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                :computed-getter="'get_subdivision_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Підрозділ"
                :show-select-on-focus="false"
                :required="false"
                :clearable="true"
                :select-button-object="{
                                  name: 'SUBDIVISION_ELEMENTS',
                                  group: '',
                                  hierarchy: false,
                                  only_groups: false,
                                  title: 'Список підрозділ',
                                  element_title: 'Підрозділ',
                                  clearable: true,
                                  show: true
                                }"
            />
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <month_input v-model="month" label="Місяць: " required @inputValue="monthChange"/>
          </div>
        </div>
        <div class="document-header-row px-2 pt-1 pb-1">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <select_input
                v-model="fill_type"
                :import-getter="{ name: 'personal_timesheet_document_fill_type' }"
                :input-value-as-value="false"
                label="Вид заповнення: "
                clearable
                @selectChange="onFillTypeChange"
            />
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date_start" label="Період з: " required :time="false"
                        :disabled="fill_type !== 'by_custom_date'"/>
          </div>
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date_end" label="по: " required :time="false"
                        :disabled="fill_type !== 'by_custom_date'"/>
          </div>
        </div>
      </div>
      <div class="document-body">
        <v-dialog v-model="showPersonAdd" hide-overlay persistent max-width="400px">
          <v-card>
            <v-card-text>
              <v-list
                  subheader
              >
                <v-subheader>Працівники організації</v-subheader>
                <v-list-item-group v-model="selected_person_add" multiple>
                  <v-list-item v-for="(person, idx) in person_list" :key="`person-list-${idx}`">
                    <template v-slot:default="{ active, }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="success"/>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ person.full_name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click.stop="showPersonAdd = false">Закрити</v-btn>
              <v-btn depressed @click.stop="addPersons">Перенести</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="block-divider">
          Дані табелю робочого часу
        </div>
        <div class="document-body-row px-2 pt-1" style="display: block !important;">
          <div class="mb-2">
            <v-btn small depressed color="grey lighten-2 mr-2" @click.stop="fillData">
              Заповнити
            </v-btn>
            <v-btn small depressed color="grey lighten-2" @click.stop="showPersonAdd = true">
              Підбір
            </v-btn>
          </div>
          <v-menu
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
              :close-on-content-click="false"
              allow-overflow
              rounded
          >
            <v-card width="400px">
              <v-card-text>
                <div>Обрані:</div>
                <v-chip-group mandatory style="min-height: 45px; position: relative" class="grey lighten-4 px-2">
                  <v-chip
                      v-for="chip in selected_day.values"
                      :key="chip.date"
                      close
                      @click:close="onChipClose(chip)"
                      style="font-size: .8rem; color: #2d2d2d; text-transform: uppercase"
                  >
                    {{ `${timesheet_letter_list[chip.timesheet_letter].name}: ${chip.hours}` }}
                  </v-chip>
                  <v-btn icon absolute style="right: 0; top: 5px" @click="clearAll">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-chip-group>
                <v-divider></v-divider>
                <div class="d-flex mt-2">
                  <div style="flex: 1 1 60%">
                    <div>Вид годин</div>
                    <div style="display: flex; flex-wrap: wrap">
                      <div class="chip-custom col mr-1 mb-2 white--text elevation-3"
                           style="padding: 5px 6px !important; cursor: pointer;"
                           v-for="(chip, idx_chip) in timesheet_letter_array"
                           :key="`chip-${idx_chip}`"
                           :style="chip.main ? '' : 'min-width: 27px !important;'"
                           :class="selected_day.current === chip.value ? 'success' : chip.main ? 'grey darken-2' : ' white grey--text text--darken-2'"
                           @click="onChipClick(chip)"
                      >
                        {{ chip.name }}
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 40%; padding-left: 16px; padding-right: 12px">
                    <div>К-сть годин</div>
                    <div class="hour-input">
                      <input :disabled="!selected_day.current" type="text" v-decimal v-model="selected_day.hours">
                    </div>
                    <div class="mt-4">
                      <v-btn block depressed class="mb-2 success" @click.stop="addToSelectValue">Обрати</v-btn>
                      <v-btn block depressed class="mb-2 secondary" @click.stop="saveSelectValue">Зберегти</v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu
              v-model="showPersonDelete"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
              :close-on-content-click="false"
              allow-overflow
              rounded
          >
            <v-list dense rounded nav>
              <v-list-item link class="px-4" @click.stop="deleteItem">
                <v-list-item-icon>
                  <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-card style="overflow: auto">
            <div class="timesheet-header">
              <div class="timesheet-row">
                <div class="timesheet-col" style="flex: 0 0 160px; padding-left: 10px; text-align: left">
                  Працівник
                </div>
                <div class="timesheet-col" style="flex: 0 0 60px">
                  Всього
                </div>
                <div class="timesheet-col"
                     v-for="(item, idx) in days"
                     :key="`header-col-idx-${idx}`"
                     :style="`flex: 1 1 ${col_width}%; min-width: 32px;`"
                     :class="item.class"
                >
                  <div class="day-name">{{ day_names[item.day_of_week] }}</div>
                  <div class="day-number">{{ item.day }}</div>
                </div>
              </div>
            </div>
            <div class="timesheet-body">
              <div class="timesheet-row"
                   v-for="(person, idx) in persons"
                   :key="`person-${person.id}_${idx}`"
              >
                <div class="timesheet-col"
                     style="flex: 0 0 160px; padding-left: 10px; font-weight: 500; position:relative;">
                  <span style="width: 75% !important; display: block; line-height: 16px;">
                    {{ person.full_name }}
                  </span>
                  <v-btn small icon :ripple="false" absolute style="right: 6px; top: 50%; transform: translateY(-50%)"
                         @click.stop="onPersonMenuClick($event, person.id)"
                  >
                    <v-icon size="22">mdi-dots-vertical</v-icon>
                  </v-btn>
                </div>
                <div class="timesheet-col" style="flex: 0 0 60px">
                  <div
                      v-for="(col_value, idx_value) in person.total"
                      :key="`person-${person.id}_${idx}-${idx_value}-total`"
                      style="margin-top: 1px; margin-bottom: 2px;"
                  >
                    <div class="chip-total">
                      <template v-if="col_value.hours.toString().indexOf('.') !== -1">
                        {{ `${timesheet_letter_list[col_value.timesheet_letter].name} ${col_value.hours.toFixed(2)}` }}
                      </template>
                      <template v-else>
                        {{ `${timesheet_letter_list[col_value.timesheet_letter].name} ${col_value.hours}` }}
                      </template>
                    </div>
                  </div>
                </div>
                <div class="timesheet-col"
                     v-for="(col, idx_col) in person.days"
                     :key="`person-${person.id}_${idx}-${idx_col}`"
                     :style="`flex: 1 1 ${col_width}%; min-width: 32px; padding: 3px; cursor: pointer`"
                     @click="onItemClick($event, person.id, col.date)"
                >
                  <div
                      v-for="(col_value, idx_value) in col.values"
                      :key="`person-${person.id}_${idx}-${idx_col}-${idx_value}`"
                      style="margin-top: 1px; margin-bottom: 2px;"
                  >
                    <div class="chip-custom">
                      {{ `${timesheet_letter_list[col_value.timesheet_letter].name} ${col_value.hours}` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="position: absolute; bottom: 0; right: 0">
      <v-spacer></v-spacer>
      <document_print v-model="show_print"
                      :doc_type="'DOCUMENT_PERSON_TIMESHEET'"
                      :doc_id="itemId"
                      :doc_form="'v1'"
      ></document_print>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
        <v-icon color="teal">mdi-printer</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
  UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
  REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
  CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {
  endOfMonth,
  beginOfMonth,
  get_half_of_month,
  getDateFromString,
  getCurrentDate,
  format_date_from_python
} from "@/utils/icons";
import {timesheet_letter_array, timesheet_letter_list} from "@/utils/accounting";
import calendarAPI from "@/utils/axios/accounting/calendar";
import personAPI from "@/utils/axios/accounting/personal"
import personalTimesheetAPI from "@/utils/axios/accounting/documents/personal_timesheet"

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";
import {formatNumber, formatToFixed} from "../../filters";

const modalDeleteId = 'personal_timesheet_accounting_modal_delete'

export default {
  name: "HWP_Modal_Work_Schedule",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    month_input: () => import("@/components/accounting/input/document_month_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      timesheet_letter_list,
      timesheet_letter_array,
      showMenu: false,
      showPersonDelete: false,
      showPersonAdd: false,
      x: 0,
      y: 0,
      selected_day: {},
      selected_person: null,
      selected_person_add: [],
      person_list: [],
      items: [],
      persons: [],
      days: [],
      days_array: [],
      day_names: {
        1: 'Пн',
        2: 'Вт',
        3: 'Ср',
        4: 'Чт',
        5: 'Пт',
        6: 'Сб',
        7: 'Нд',
      },

      subdivision_id: this.item.subdivision_id,
      fill_type: this.item.fill_type,
      month: this.item.month,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
    }
  },
  methods: {
    formatToFixed,
    formatNumber,
    checkDocument(show = false, table = false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'fill_type': {id: 'fill_type', text: 'Не заповнена "вид заповнення"'},
        'date_start': {id: 'date', text: 'Не заповненаий початок періоду'},
        'date_end': {id: 'date', text: 'Не заповненаий кінець періоду'},
        'month': {id: 'date', text: 'Не заповненаий місяць'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      if (!this.persons.length) {
        this.document_state.errors.push(
            {table: 'Шапка', row_num: 0, text: 'Таблиця із відпрацьованими годинами порожня. Додайте записи або виконайте команду "Заповнити/Підбір" '}
        )
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        console.log('check table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    getTotals() {},
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    monthChange() {
      if (this.month) {
        if (!this.fill_type) {
          this.date_start = beginOfMonth(this.month)
          this.date_end = endOfMonth(this.month)
        }
        if (this.fill_type === 'by_month') {
          this.date_start = beginOfMonth(this.month)
          this.date_end = endOfMonth(this.month)
        }
        if (this.fill_type === 'by_first_part') {
          let half = get_half_of_month(this.month)
          this.date_start = half.date_start
          this.date_end = half.date_end
        }
        if (this.fill_type === 'by_second_part') {
          let half = get_half_of_month(this.month, false)
          this.date_start = half.date_start
          this.date_end = half.date_end
        }
        this.fetchData()
      }
    },
    clearAll() {
      this.selected_day.values = []
    },
    addToSelectValue() {
      if (!this.selected_day.current) return
      const item = this.selected_day.values.find(i => i.timesheet_letter === this.selected_day.current)

      if (!item) {
        this.selected_day.values.push(
            {timesheet_letter: this.selected_day.current, hours: this.selected_day.hours}
        )
      } else {
        item.hours = this.selected_day.hours
      }
      this.selected_day.hours = 0
      this.selected_day.current = ''
    },
    saveSelectValue() {
      const person = this.persons.find(p => p.id === this.selected_day.person_id)
      if (person) {
        const c = person.days.find(d => d.date === this.selected_day.date)
        if (c) {
          const total_row = person.total
          c.values.forEach(cv => {
            const total_find = total_row.find(tf => tf.timesheet_letter === cv.timesheet_letter)
            if (total_find) {
              total_find.hours -= cv.hours
              total_find.days -= cv.days
            }
          })
          this.selected_day.values.forEach(sd => {
            const total_find = total_row.find(tf => tf.timesheet_letter === sd.timesheet_letter)
            if (total_find) {
              total_find.hours += +sd.hours
              total_find.days += 1
            } else {
              total_row.push(
                  {
                    timesheet_letter: sd.timesheet_letter,
                    hours: sd.hours,
                    days: 1
                  }
              )
            }
          })
          c.values = this.selected_day.values
        }
      }
      this.selected_day = {date: null, current: '', hours: 0, person_id: null, values: []}
      this.showMenu = false
    },
    onFillTypeChange(payload) {
      this.fill_type = (payload || {}).value
      this.monthChange()
      this.fetchData()
    },
    onChipClick(payload) {
      this.$nextTick(() => {
        this.selected_day.current = payload.value
      })
    },
    onChipClose(payload) {
      const delete_idx = this.selected_day.values.indexOf(payload)
      this.selected_day.values.splice(delete_idx, 1)
    },
    onItemClick(e, person_id, date) {
      this.showMenu = false
      const person = this.persons.find(p => p.id === person_id)
      this.selected_day = {date, current: '', hours: 0, person_id}
      if (person) {
        const c = person.days.find(d => d.date === date)
        if (c) {
          this.selected_day = {date, values: c.values.map(i => Object.assign({}, i)), current: '', hours: 0, person_id}
        }
      }
      if (!this.selected_day.values) {
        this.selected_day.values = []
      }
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    onPersonMenuClick(e, person_id) {
      this.showPersonDelete = false
      this.selected_person = person_id
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showPersonDelete = true
      })
    },
    buildCols() {
      const date_start_js = getDateFromString(this.date_start)
      const date_end_js = getDateFromString(this.date_end)
      const year = date_start_js.getFullYear()
      const month = date_start_js.getMonth() + 1
      this.days = []
      this.days_array = []

      for (let day = date_start_js.getDate(); day <= date_end_js.getDate(); day++) {
        let day_of_week_ukr = 0

        const day_of_week = new Date(year, month - 1, day).getDay()

        if (day_of_week === 0) {
          day_of_week_ukr = 7
        } else {
          day_of_week_ukr = day_of_week
        }

        const date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
        const calendar_item = this.calendar_items.find(i => i.date === date)

        let day_type = ''
        let class_type = ''


        if (day_of_week_ukr <= 5) {
          day_type = 'work'
          class_type = 'work'
        } else if (day_of_week_ukr === 6) {
          day_type = 'opening'
          class_type = 'opening-1'
        } else if (day_of_week_ukr === 7) {
          day_type = 'opening'
          class_type = 'opening-2'
        }

        if (calendar_item) {
          day_type = calendar_item.day_type

          if (day_type === 'work') {
            class_type = 'work'
          } else if (day_type === 'preopening') {
            class_type = 'preopening-1'
          } else if (day_type === 'holiday') {
            class_type = 'holiday-1'
          }
        }


        this.days.push(
            {
              day: day,
              date: date,
              type: day_type,
              day_of_week: day_of_week_ukr,
              class: class_type,
            }
        )
        this.days_array.push(day)
      }
    },
    buildPersonDataForOne(person_id) {
      const persons = {}

      this.items.filter(item => item.person_id === person_id).forEach(i => {
        if (i.person_id in persons) {
          const f = persons[i.person_id].days.find(d => d.date === i.date)
          if (f) {
            const v = f.values.find(c => c.timesheet_letter === i.timesheet_letter)
            const total_row = persons[i.person_id].total
            const total_find = total_row.find(tf => tf.timesheet_letter === i.timesheet_letter)
            if (total_find) {
              total_find.hours += i.hours
              total_find.days += 1
            } else {
              total_row.push(
                  {
                    timesheet_letter: i.timesheet_letter,
                    hours: i.hours,
                    days: 1
                  }
              )
            }
            if (!v) {
              f.values.push(
                  {timesheet_letter: i.timesheet_letter, hours: i.hours}
              )
            }
          }
        } else {
          const total = []
          const days = this.days.map(a => {
            return {date: a.date, values: []}
          })
          const d = days.find(f => f.date === i.date)
          if (d) {
            d.values.push({timesheet_letter: i.timesheet_letter, hours: i.hours})
          }
          d.values.forEach(t => {
            const total_find = total.find(tf => tf.timesheet_letter === t.timesheet_letter)
            if (total_find) {
              total_find.hours += t.hours
              total_find.days += 1
            }
          })
          persons[i.person_id] = {id: i.person_id, full_name: i.full_name, days: days, total}
        }
      })

      const p = this.persons.find(i => i.person_id === person_id)

      if (p) {
        this.persons.splice(this.persons.indexOf(p), 1)
      }

      Object.keys(persons).forEach(i => {
        const value = persons[i]
        this.persons.push(Object.assign({}, value))
      })
    },
    buildPersonData() {
      const persons = {}

      this.items.forEach(i => {
        if (i.person_id in persons) {
          const f = persons[i.person_id].days.find(d => d.date === i.date)
          if (f) {
            const v = f.values.find(c => c.timesheet_letter === i.timesheet_letter)
            const total_row = persons[i.person_id].total
            const total_find = total_row.find(tf => tf.timesheet_letter === i.timesheet_letter)
            if (total_find) {
              total_find.hours += i.hours
              total_find.days += 1
            } else {
              total_row.push(
                  {
                    timesheet_letter: i.timesheet_letter,
                    hours: i.hours,
                    days: 1
                  }
              )
            }
            if (!v) {
              f.values.push(
                  {timesheet_letter: i.timesheet_letter, hours: i.hours}
              )
            }
          }
        } else {
          const total = []
          const days = this.days.map(a => {
            return {date: a.date, values: []}
          })
          const d = days.find(f => f.date === i.date)
          if (d) {
            d.values.push({timesheet_letter: i.timesheet_letter, hours: i.hours})
          }
          d.values.forEach(t => {
            const total_find = total.find(tf => tf.timesheet_letter === t.timesheet_letter)
            if (total_find) {
              total_find.hours += t.hours
              total_find.days += 1
            }
          })
          persons[i.person_id] = {id: i.person_id, full_name: i.full_name, days: days, total}
        }
      })
      this.persons = Object.keys(persons).map(i => {
        const value = persons[i]
        return Object.assign({}, value)
      })
    },
    addPersons() {
      const person_ids = []
      this.selected_person_add.forEach(idx => {
        const person = this.person_list[idx]

        if (!this.persons.find(p => p.id === person.id)) {
          // const days = this.days.map(a => {
          //   return {date: a.date, values: []}
          // })
          // this.persons.push(
          //     {id: person.id, full_name: person.full_name, days: days}
          // )
          person_ids.push(person.id)
        }
      })

      personalTimesheetAPI.fill_all({
        date_start: this.date_start,
        date_end: this.date_end,
        subdivision_id: this.subdivision_id || null,
        person_id: person_ids
      }).then(response => response.data)
          .then(data => {
            this.items = [...data, ...this.items]
            person_ids.forEach(i => {
              this.buildPersonDataForOne(i)
            })
          })
          .finally(() => {
            this.selected_person_add = []
            this.showPersonAdd = false
          })
    },
    fetchData() {
      const year = this.month ? +this.month.split('-')[0] : +getCurrentDate().split('-')[0]
      this.person_list = []
      this.selected_person_add = []

      calendarAPI.get(year)
          .then(response => response.data)
          .then(data => {
            this.calendar_items = data.items

            this.buildCols()

            if (!this.isNew) {
              personalTimesheetAPI.get_rows(this.itemId)
                  .then(response => response.data)
                  .then(data => {
                    this.items = data
                    this.buildPersonData()
                  })
            }

            personAPI.get_main_actual(this.date_end)
                .then(response => response.data)
                .then(data => {
                  this.person_list = data
                })
          })

    },
    fillData() {
      personalTimesheetAPI.fill_all({
        date_start: this.date_start,
        date_end: this.date_end,
        subdivision_id: this.subdivision_id || null,
        person_id: null
      })
          .then(response => response.data)
          .then(data => {
            this.items = data
            this.buildPersonData()
          })
    },
    deleteItem() {
      if (!this.selected_person) return
      this.items.filter(i => i.person_id === this.selected_person).forEach(i => {
        this.items.splice(this.items.indexOf(i), 1)
      })
      this.persons.filter(i => i.id === this.selected_person).forEach(i => {
        this.persons.splice(this.persons.indexOf(i), 1)
      })
      this.selected_person = null
      this.showPersonDelete = false
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'date_start': 'Період з',
        'date_end': 'Період по',
        'month': 'Місяць',
        'fill_type': 'Вид заповнення',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      return {
        valid: form_valid,
        text: errors.join(', ')
      }

    },
    closeModal() {
      this.$emit('closeModal')

      this.selected_day = {}
      this.selected_person = null
      this.selected_person_add = []
      this.person_list = []
      this.items = []
      this.persons = []
      this.days = []
      this.days_array = []


      this.conducted = this.item.conducted
      this.date = this.item.date
      this.number = this.item.number
      this.subdivision_id = this.item.subdivision_id
      this.fill_type = this.item.fill_type
      this.month = this.item.month
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.itemId = this.item.id

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення табеля обліку робочого часу № ${this.number} від ${format_date_from_python(this.date)}}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getRows() {
      const rows = []
      this.persons.forEach(person => {
        const items = person.days.filter(day => day.values.length)
        items.forEach(d => {
          d.values.forEach(i => {
            rows.push(
                {person_id: person.id, date: d.date, timesheet_letter: i.timesheet_letter, hours: i.hours}
            )
          })
        })
      })
      return rows
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        subdivision_id: this.subdivision_id,
        date_start: this.date_start,
        date_end: this.date_end,
        month: this.month,
        fill_type: this.fill_type,
        rows: this.getRows()
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: false,
            immediate: true,
            handler(payload) {
              this.selected_day = {}
              this.selected_person = null
              this.selected_person_add = []
              this.person_list = []
              this.items = []
              this.persons = []
              this.days = []
              this.days_array = []

              this.conducted = payload.conducted
              this.date = payload.date
              this.number = payload.number
              this.itemId = payload.id
              this.fill_type = payload.fill_type
              this.month = payload.month
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.subdivision_id = payload.subdivision_id || null

              this.fetchData()
              this.checkDocument()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
  },
  computed: {
    col_width() {
      return (100 / this.days.length).toPrecision(4)
    }
  },
}
</script>

<style scoped lang="scss">
.timesheet-row {
  display: flex;

  .timesheet-col {
    padding: 2px;

  }
}

.timesheet-header {
  font-size: .82rem;

  .timesheet-row {
    .timesheet-col {
      border-right: .5px solid #e8e8e8;
      background-color: #f5f5f5;
      text-align: center;

      .day-name {
        font-size: .78rem;
      }

      .day-number {
        font-size: .8rem;
      }
    }

    .timesheet-col:last-child {
      border-right: none !important;
    }
  }
}

.timesheet-body {
  font-size: .8rem;

  .timesheet-row {

    .timesheet-col {
      border-bottom: .5px solid #e8e8e8;
      border-right: .5px solid #e8e8e8;
    }

    .timesheet-col:last-child {
      border-right: none !important;
    }
  }
}

.chip-custom {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  font-size: .76rem;
  text-align: center;
  padding: 1px 2px;
  border-radius: 4px;
  cursor: pointer;
}

.chip-total {
  width: 100%;
  height: 100%;
  background-color: var(--v-success-base);
  color: white;
  font-size: .76rem;
  text-align: center;
  padding: 1px 2px;
  border-radius: 4px;
  cursor: pointer;
}

.hour-input {
  input {
    width: 100%;
    border: none;
    outline: 0;
    font-size: 3rem;
    text-align: center;
    padding: 10px;
    background: #e7e7e7;
  }
}
</style>